import { MinigameCenter } from '../pages/Minigame';

function AdminMinigameIndexRoute() {
  return <MinigameCenter />;
}

export const Component = AdminMinigameIndexRoute;

export function clientLoader() {
  return null;
}
